import React, { FC } from 'react';
import { graphql, Link } from 'gatsby';
import Container from 'react-bootstrap/Container';

import ControlledCarousel from 'components/ControlledCarousel';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import IconCustom from 'components/common/IconCustom';
import GatsbyImage from 'components/common/GatsbyImage';

import { SymptomsListProps } from './models';
import responsiveObj from './constants';
import './SymptomsList.scss';

const SymptomsList: FC<SymptomsListProps> = ({ symptomsListItems }) => (
  <div className="symptoms-list" data-testid="symptoms-list">
    <Container fluid>
      <ControlledCarousel
        showNavigators
        classes="symptoms-list__carousel"
        responsiveObj={responsiveObj}
      >
        {symptomsListItems.map(({ properties }) => {
          return (
            <Link
              key={`${properties.symptomDataLink[0].name}`}
              to={properties.symptomDataLink[0].url}
              className="symptoms-list-item__link"
              aria-label={properties.symptomDataLink[0].name}
            >
              <div className="symptoms-list-item__image">
                <GatsbyImage
                  fluid={properties.symptomDataImage}
                  alt={properties.symptomDataImageAlt}
                />
              </div>
              <div className="symptoms-list-item__content">
                <DangerouslySetInnerHtml
                  html={properties.symptomDataLink[0].name}
                  element="p"
                  className="symptoms-list-item__text"
                />
                <IconCustom icon="chevron-right" />
              </div>
            </Link>
          );
        })}
      </ControlledCarousel>
    </Container>
  </div>
);

export const query = graphql`
  fragment FragmentSymptomsList on TSymptomsListStructure {
    structure
    properties {
      symptomsListItems {
        properties {
          symptomDataImageAlt
          symptomDataImage {
            ...FragmentGatsbyImage
          }
          symptomDataLink {
            url
            name
            queryString
          }
        }
      }
    }
  }
`;

export default SymptomsList;
