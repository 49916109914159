const responsiveObj = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 768, min: 420 },
    items: 2,
  },
  smallMobile: {
    breakpoint: { max: 420, min: 0 },
    items: 1,
  },
};

export default responsiveObj;
